<template>
  <div>
    <el-radio-group v-model="expStatus">
      <el-radio :label="true">使用公式</el-radio>
      <el-radio :label="false">不使用公式</el-radio>
    </el-radio-group>
  </div>
  <div class="big_box4">
    <div class="top_title3">
      <h4>企业所得税</h4>
      <h5>一般纳税人、小规模纳税人适用</h5>
      <div class="bottom_font clearfix">
        <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
        <h6 class="center">税款所属期：<span>{{durationOfTaxTime}}</span></h6>
        <h6 class="right">
          <p>单位：元</p>
          <p>纳税人识别号：<span>{{taxNo}}</span></p>
        </h6>
      </div>
    </div>
    <!-- A类报表 -->
    <div v-if="qysdsType == 1"  class="fixed_box_table" :style="{height:contentStyleObj}" style="margin-top:10px;border-top: 1px solid #c2c2c2;">
      <table class="content" cellspacing="0">
        <tr>
          <td colspan="2" class="center bg_color">预缴方式</td>
          <td colspan="8" class="center">
            <el-radio v-model="checked" label="0">按照实际利润额预缴</el-radio>
            <el-radio label="1" disabled>按照上一纳税年度应纳税所得额平均额预缴</el-radio>
            <el-radio label="2" disabled>按照税务机关确定的其他方法预缴</el-radio>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="center bg_color">企业类型</td>
          <td colspan="8" class="center ">
            <el-radio v-model="checked2" label="0">一般企业</el-radio>
            <el-radio label="1" disabled>跨地区经营汇总纳税企业总机构</el-radio>
            <el-radio label="2" disabled>跨地区经营汇总纳税企业分支机构</el-radio>
          </td>
        </tr>
        <tr class="top_bg">
          <td class="center" colspan="10">优惠及附报事项有关信息</td>
        </tr>
        <tr>
          <td rowspan="2" class="center bg_color" style="width:10%">项目</td>
          <td colspan="2" class="center bg_color">一季度</td>
          <td colspan="2" class="center bg_color">二季度</td>
          <td colspan="2" class="center bg_color">三季度</td>
          <td colspan="2" class="center bg_color">四季度</td>
          <td rowspan="2" class="center bg_color" style="width:10%">季度平均值</td>
        </tr>

        <tr>
          <td class="center bg_color">季初</td>
          <td class="center bg_color">季末</td>
          <td class="center bg_color">季初</td>
          <td class="center bg_color">季末</td>
          <td class="center bg_color">季初</td>
          <td class="center bg_color">季末</td>
          <td class="center bg_color">季初</td> 
          <td class="center bg_color">季末</td>
        </tr>

        <tr>
          <td class="center bg_color">从业人数</td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qccyrs1" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qmcyrs1" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qccyrs2" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qmcyrs2" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qccyrs3" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qmcyrs3" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qccyrs4" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qmcyrs4" @change="changeList()"></qzf-str-td>
          <td class="center ">{{ this.info.jdpjzRs }}</td>
        </tr>
        <tr>
          <td class="center bg_color">资产总额（万元）</td>
          <qzf-str-td :disabled="false" v-model:amount="info.qczcze1" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qmzcze1" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qczcze2" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qmzcze2" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qczcze3" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qmzcze3" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qczcze4" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qmzcze4" @change="changeList()"></qzf-str-td>
          <td class="center ">{{ this.info.jdpjzZC }}</td>
        </tr>
        <tr>
          <td class="center bg_color" colspan="2">国家限制或禁止行业</td>
          <td class="center" colspan="3">
            <el-radio v-model="info.xzhy" :label="1" disabled>是</el-radio>
            <el-radio v-model="info.xzhy" :label="0">否</el-radio> 
          </td>
          <td class="center bg_color" colspan="2">小型微利企业</td>
          <td colspan="3" class="center">
            <el-radio v-model="info.xxwlqy" :label="1">是</el-radio>
            <el-radio v-model="info.xxwlqy" :label="0">否</el-radio> 
          </td>
        </tr>
      </table>
      <!-- 附报事项名称 -->
      <table class="content" cellspacing="0">
        <tr class="top_bg">
          <td class="center" style="width:8%">代码</td>
          <td class="center" colspan="2" style="width:72%;">附报事项名称</td>
          <td class="center" style="width:20%;">金额或选项</td>
        </tr>
        <tr>
          <td class="center bg_color" style="width:8%">K01001</td>
          <td colspan="2" style="width:72%;">
            <el-checkbox v-model="info.k01001Status"></el-checkbox>
            支持新型冠状病毒感染的肺炎疫情防控捐赠支出全额扣除（本年累计，元）
          </td>
          <qzf-str-td style="width:20%" :disabled="false" v-model:amount="info.k01001" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color" style="width:8%">K01002</td>
          <td colspan="2" style="width:72%;">
            <el-checkbox v-model="info.k01002Status"></el-checkbox>
            扶贫捐赠支出全额扣除（本年累计，元）
          </td>
          <qzf-str-td style="width:20%" :disabled="false" v-model:amount="info.k01002" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color" style="width:8%">Y01001</td>
          <td colspan="2" style="width:72%;">
            <el-checkbox v-model="info.y01001Status" :disabled="info.y01001 != ''"></el-checkbox>
            软件集成电路企业按新政策或原政策执行优惠（单选：原政策/新政策）
          </td>
          <td >
            <el-select style="width:100%;padding-top:1px" v-model="info.y01001" placeholder="请选择政策" @change="changeList" size="small" clearable>
              <el-option size="small" v-for="(item,index) in typeList" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select> 
          </td>
        </tr>
      </table>
      <!-- 附报事项名称 end -->
      <table class="content" cellspacing="0">
        <tr class="top_bg">
          <td colspan="4" class="center weight">预缴税款计算</td>
        </tr>
        <tr class="top_bg">
          <td class="center weight" style="width:8%">行次</td>
          <td colspan="2" class="center weight" style="width:72%;">项目</td>
          <td class="center weight" style="width:20%;">本年累计金额</td>
        </tr>
      </table>
      <table class="content" cellspacing="0">
        <tr>
          <td class="center bg_color" style="width:8%">1</td>
          <td colspan="5" style="width:72%;" class="bg_color">营业收入</td>
          <qzf-str-td style="width:20%" :disabled="false" v-model:amount="info.yysr" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">2</td>
          <td colspan="5" class="bg_color">营业成本</td>
          <qzf-str-td :disabled="false" v-model:amount="info.yycb" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">3</td>
          <td colspan="5" class="bg_color">利润总额</td>
          <qzf-str-td :disabled="false" v-model:amount="info.lrze" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">4</td>
          <td colspan="5" class="bg_color">加：特定业务计算的应纳税所得额</td>
          <qzf-str-td :disabled="false" v-model:amount="info.tdywjsdynssde" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">5</td>
          <td colspan="5" class="bg_color">减：不征税收入</td>
          <qzf-str-td :disabled="false" v-model:amount="info.bzssr" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">6</td>
          <td colspan="5" class="bg_color">	减：资产加速折旧、摊销（扣除）调减额（填写A201020）</td>
          <qzf-str-td :disabled="false" v-model:amount="info.zcjszj" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">7</td>
          <td colspan="5" class="bg_color">减：免税收入、减计收入、加计扣除（7.1+7.2+......）<p @click="openExempt()">填写优惠事项</p></td>
          <qzf-str-td :disabled="false" v-model:amount="info.mssr" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">8</td>
          <td colspan="5" class="bg_color">减：所得减免（8.1+8.2+......）<p @click="openReduce()">填写优惠事项</p></td>
          <qzf-str-td :disabled="false" v-model:amount="info.sdsjm" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">9</td>
          <td colspan="5" class="bg_color">减：弥补以前年度亏损</td>
          <qzf-str-td :disabled="false" v-model:amount="info.mbyqndks" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">10</td>
          <td colspan="5" class="bg_color">实际利润额（3+4-5-6-7-8-9） \ 按照上一纳税年度应纳税所得额平均额确定的应纳税所得额</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.sjlre" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">11</td>
          <td colspan="5" class="bg_color">税率(25%)</td>
          <qzf-str-td :disabled="false" v-model:amount="info.sl" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">12</td>
          <td colspan="5" class="bg_color">应纳所得税额（10×11）</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.ynssde" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">13</td>
          <td colspan="5" class="bg_color">减：减免所得税额（13.1+13.2+......）<p @click="openType()">填写优惠事项</p></td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.jmsdse" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">14</td>
          <td colspan="5" class="bg_color">减：本年实际已缴纳所得税额</td>
          <qzf-str-td :disabled="false" v-model:amount="info.bnsjyjn" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">15</td>
          <td colspan="5" class="bg_color">减：特定业务预缴（征）所得税额</td>
          <qzf-str-td :disabled="false" v-model:amount="info.tdyeyj" @change="changeList()"></qzf-str-td>
        </tr>

        <tr>
          <td class="center bg_color">16</td>
          <td colspan="5" class="bg_color">本期应补（退）所得税额（12-13-14-15） \ 税务机关确定的本期应纳所得税额</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.bqybt" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td colspan="7" class="center bg_color">实际缴纳企业所得税计算</td>
        </tr>
        <tr>
          <td class="center bg_color">FZ1</td>
          <td colspan="5" class="bg_color">中央级收入实际应纳税额[本期：16行×60%或(18行+20行)×60%+19或22行×60%]</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.zyjsr" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">FZ2</td>
          <td colspan="5" class="bg_color">地方级收入应纳税额[本期：16行×40%或(18行+20行)×40%或22行×40%]</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.dfjsr" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">23</td>
          <td class="center bg_color" rowspan="3">减：民族自治地区企业所得税地方分享部分：</td>
          <td class="center" rowspan="3">
            <el-radio v-model="info.mzzzqStatus" :label="0" @change="changeList()">无</el-radio>
            <el-radio v-model="info.mzzzqStatus" :label="1" @change="changeList()">免征</el-radio> 
            <el-radio v-model="info.mzzzqStatus" :label="2" @change="changeList()">减征</el-radio> 
          </td>
          <td class="center bg_color" rowspan="3">减征幅度</td>
          <td class="center" rowspan="3">
            <el-input type="text" size="small" style="width:80%" v-model="info.mzzzqFd" @change="changeList()"></el-input>%
          </td>
          <td class="bg_color">本期实际减免金额（FZ2×减征幅度）</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.mzzzqBq" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">23.1</td>
            <td class="bg_color">本机构本年累计的（23行的本年累计）</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.mzzzqBn" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">23.2</td>
          <td class="bg_color">本年累计应减免金额（总机构及分支机构的本年累计，总机构填报）</td>
          <qzf-str-td :disabled="false" v-model:amount="info.mzzzqBnym" @change="changeList()"></qzf-str-td>
        </tr>
        <tr>
          <td class="center bg_color">FZ3</td>
          <td colspan="5" class="bg_color">地方级收入实际应纳税额（本期：FZ2-23）</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.dfjsrsjynse" @change="changeList()"></qzf-str-td>
        </tr>
          <tr>
          <td class="center bg_color">24</td>
          <td colspan="5" class="bg_color">实际应补（退）所得税额（本期：FZ1+FZ3）</td>
          <qzf-str-td :disabled="expStatus" v-model:amount="info.sjybtse" @change="changeList()"></qzf-str-td>
        </tr>
      </table>
    </div>
    <!-- B类报表 -->
    <div v-if="qysdsType == 2" class="fixed_box_table" :style="{height:contentStyleObj}" style="margin-top:10px;border-top: 1px solid #c2c2c2;">
      <table class="content" cellspacing="0">
        <tr>
          <td colspan="2" class="center" style="width:8%">核定征收方式</td>
          <td colspan="9" style="width:82%">
            <el-radio v-model="info.hdzsfs" label="1">核定应税所得率（能核算收入总额的）</el-radio>
            <el-radio label="2" disabled>核定应税所得率（能核算成本费用总额的）</el-radio>
            <el-radio label="3" disabled>核定应纳所得税额</el-radio>
          </td>
        </tr>
        <tr class="top_bg">
          <td class="center" colspan="11">按季度填报信息</td>
        </tr>
        <tr>
          <td rowspan="2" colspan="2" class="center bg_color" style="width:10%">项目</td>
          <td colspan="2" class="center bg_color">一季度</td>
          <td colspan="2" class="center bg_color">二季度</td>
          <td colspan="2" class="center bg_color">三季度</td>
          <td colspan="2" class="center bg_color">四季度</td>
          <td rowspan="2" class="center bg_color" style="width:10%">季度平均值</td>
        </tr>

        <tr>
          <td class="center bg_color">季初</td>
          <td class="center bg_color">季末</td>
          <td class="center bg_color">季初</td>
          <td class="center bg_color">季末</td>
          <td class="center bg_color">季初</td>
          <td class="center bg_color">季末</td>
          <td class="center bg_color">季初</td> 
          <td class="center bg_color">季末</td>
        </tr>

          <tr>
          <td class="center bg_color" colspan="2">从业人数</td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qccyrs1" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qmcyrs1" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qccyrs2" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qmcyrs2" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qccyrs3" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qmcyrs3" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qccyrs4" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" :disabled="false" v-model:amount="info.qmcyrs4" @change="changeList()"></qzf-str-td>
          <td class="center ">0</td>
        </tr>
        <tr>
          <td class="center bg_color" colspan="2">资产总额（万元）</td>
          <qzf-str-td :disabled="false" v-model:amount="info.qczcze1" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qmzcze1" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qczcze2" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qmzcze2" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qczcze3" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qmzcze3" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qczcze4" @change="changeList()"></qzf-str-td>
          <qzf-str-td :disabled="false" v-model:amount="info.qmzcze4" @change="changeList()"></qzf-str-td>
          <td class="center ">0</td>
        </tr>
        <tr>
          <td class="center bg_color" colspan="2">国家限制或禁止行业</td>
          <td class="center" colspan="4">
            <el-radio v-model="info.gjxzhjzhy" label="1" disabled>是</el-radio>
            <el-radio v-model="info.gjxzhjzhy" label="0">否</el-radio> 
          </td>
          <td class="center bg_color" colspan="2">小型微利企业</td>
          <td colspan="3" class="center">
            <el-radio v-model="info.xxwlqyjd" label="1">是</el-radio>
            <el-radio v-model="info.xxwlqyjd" label="0">否</el-radio> 
          </td>
        </tr>
        
      </table>
      <div class="fixed_box_table">
        <table class="content" cellspacing="0">
          <tr class="top_bg">
            <td class="center weight" style="width:8%">行次</td>
            <td colspan="7" class="center weight" style="width:76%">项目</td>
            <td class="center weight" style="width:26%" colspan="3">本年累计金额</td>
          </tr>
          <tr>
            <td class="center bg_color" style="width:8%">1</td>
            <td colspan="7" style="width:76%" class="bg_color">收入总额</td>
            <qzf-td style="width:26%" :colspan="3" :disabled="false" v-model:amount="info.srze" @change="changeList()"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">2</td>
            <td colspan="7" class="bg_color">减：不征税收入</td>
            <qzf-td :disabled="false" v-model:amount="info.jBzssr" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">3</td>
            <td colspan="7" class="bg_color">减：免税收入（4+5+10+11）</td>
            <qzf-td :disabled="false" v-model:amount="info.jMssr" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">4</td>
            <td colspan="7" class="bg_color">国债利息收入免征企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.gzlxsr" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">5</td>
            <td colspan="7" class="bg_color">符合条件的居民企业之间的股息、红利等权益性投资收益免征企业所得税（6+7.1+7.2+8+9）</td>
            <qzf-td :disabled="false" v-model:amount="info.fhtjdjmqyzjgx" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">6</td>
            <td colspan="7" class="bg_color">期中：一般股息红利等权益性投资收益免征企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.qzYbgxhl" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">7.1</td>
            <td colspan="7" class="bg_color">通过沪港通投资且连续持有H股满12个月取得的股息红利所得免征企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.qzTghgttz" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">7.2</td>
            <td colspan="7" class="bg_color">通过深港通投资且连续持有H股满12个月取得的股息红利所得免征企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.qzTgsgttz" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">8</td>
            <td colspan="7" class="bg_color">居民企业持有创新企业CDR取得的股息红利所得免征企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.jmqycycxqy" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">9</td>
            <td colspan="7" class="bg_color">符合条件的居民企业之间属于股息、红利性质的永续债利息收入免征企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.fhtjdjmqy" @change="changeList()" :colspan="3"></qzf-td>
          </tr>

          <tr>
            <td class="center bg_color">10</td>
            <td colspan="7" class="bg_color">投资者从证券投资基金分配中取得的收入免征企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.tzzczqtzjjfp" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">11</td>
            <td colspan="7" class="bg_color">取得的地方政府债券利息收入免征企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.qdddfzfzqlxsr" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">12</td>
            <td colspan="7" class="bg_color">应税收入额（1-2-3） \ 成本费用总额</td>
            <qzf-td :disabled="false" v-model:amount="info.yssre" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">13</td>
            <td colspan="7" class="bg_color">税务机关核定的应税所得率（%）</td>
            <qzf-td :disabled="false" v-model:amount="info.swjghddyssdl" @change="changeList()" :colspan="3"></qzf-td>
          </tr>

          <tr>
            <td class="center bg_color">14</td>
            <td colspan="7" class="bg_color">应纳税所得额（第12×13行） \ [第12行÷（1-第13行）×第13行]</td>
            <qzf-td :disabled="false" v-model:amount="info.ynssde" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">15</td>
            <td colspan="7" class="bg_color">税率（25%）</td>
            <qzf-td :disabled="false" v-model:amount="info.sl" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">16</td>
            <td colspan="7" class="bg_color">应纳所得税额（14×15）</td>
            <qzf-td :disabled="false" v-model:amount="info.ynsdse" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          
          <tr>
            <td class="center bg_color">17</td>
            <td colspan="7" class="bg_color">减：符合条件的小型微利企业减免企业所得税</td>
            <qzf-td :disabled="false" v-model:amount="info.jFhtjdxflqy" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">18</td>
            <td colspan="7" class="bg_color">减：实际已缴纳所得税额</td>
            <qzf-td :disabled="false" v-model:amount="info.jSjyjnsdse" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">19</td>
            <td colspan="7" class="bg_color">本期应补（退）所得税额（16-17-18） \ 税务机关核定本期应纳所得税额</td>
            <qzf-td :disabled="false" v-model:amount="info.bqybtsdse" @change="changeList()" :colspan="3"></qzf-td>
          </tr>
          <tr>
            <td class="center bg_color">20</td>
            <td colspan="7" class="bg_color">民族自治地方的自治机关对本民族自治地方的企业应缴纳的企业所得税中属于地方分享的部分减征或免征（  □ 免征    □ 减征:减征幅度____%  ）</td>
            <qzf-td :disabled="false" v-model:amount="info.mzzzdfdzzjg" @change="changeList()" :colspan="3"></qzf-td>
          </tr>

          <tr>
            <td class="center bg_color">21</td>
            <td colspan="7" class="bg_color">本期实际应补（退）所得税额</td>
            <qzf-td :disabled="false" v-model:amount="info.bqsjybtsdse" @change="changeList()" :colspan="3"></qzf-td>
          </tr>

        </table> 
      </div>
    </div>
    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <el-button type="primary" @click="saveMain()" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
      <qzf-button type="primary" @success="saveMain()" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
    </div>
  </div>

  <!-- 免税、减计收入、加计扣除类型选项表弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="免税、减计收入、加计扣除类型选项表" v-model="dialogExempt"  style="width: 70%">
      <table class="content2" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:5%;color: #fff !important;">序号</td>
          <td class="center" style="width:5%;color: #fff !important">选择</td>
          <td class="center" style="width:10%;color: #fff !important">代码</td>
          <td class="center" style="width:57%;color: #fff !important" colspan="2">优惠事项</td>
          <td class="center" style="width:15%;color: #fff !important">本年累计金额</td>
        </tr>
      </table>
      <div class="fixed_box_table">
        <table class="content2" cellspacing="0">

          <tr v-for="(item, index) in detailInfo" :key="index">
            <td style="width:5%;" class="center">{{index + 1}}</td>
            <td style="width:5%;" class="center">
              <el-checkbox v-model="item.status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td style="width:10%;" class="left">{{item.code}}</td>
            <td style="width:8%;" class="center">{{item.remark2}}</td>
            <td style="width:49%;" class="left">{{item.remark}}</td>
            <qzf-str-td style="width:15%;" :disabled="false" v-model:amount="item.amount"></qzf-str-td>
          </tr>

          <!-- <tr>
            <td class="center" style="width:5%;">1</td>
            <td class="center" style="width:5%;">
              <el-checkbox v-model="detailInfo[0].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left" style="width:10%;">MSSR010</td>
            <td class="center" style="width:8%;" rowspan="10">免税收入</td>
            <td class="left" style="width:49%;">国债利息收入免征企业所得税</td>
            <qzf-str-td style="width:15%;" :disabled="false" v-model:amount="detailInfo[0].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">2</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[1].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSR021</td>
            <td class="left">一般股息红利等权益性投资收益免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[1].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">3</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[2].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSR022</td>
            <td class="left">通过沪港通投资且连续持有H股满12个月取得的股息红利所得免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[2].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">4</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[3].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSR023</td>
            <td class="left">通过深港通投资且连续持有H股满12个月取得的股息红利所得免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[3].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">5</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[4].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSR024</td>
            <td class="left">创新企业CDR股息红利所得免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[4].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">6</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[5].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSR025</td>
            <td class="left">永续债利息收入免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[5].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">7</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[6].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSR030</td>
            <td class="left">符合条件的非营利组织的收入免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[6].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">8</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[7].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSR040</td>
            <td class="left">投资者从证券投资基金分配中取得的收入免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[7].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">9</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[8].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSR050</td>
            <td class="left">取得的地方政府债券利息收入免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[8].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">10</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[9].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">MSSRQT</td>
            <td class="left">免税收入其他</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[9].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">11</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[10].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJSR010</td>
            <td class="center" rowspan="4">减计收入</td>
            <td class="left">取得铁路债券利息收入减半征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[10].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">12</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[11].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJSR020</td>
            <td class="left">取得的社区家庭服务收入在计算应纳税所得额时减计收入</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[11].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">13</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[12].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJSR030</td>
            <td class="left">综合利用资源生产产品取得的收入在计算应纳税所得额时减计收入</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[12].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">14</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[13].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">	JJSRQT</td>
            <td class="left">减计收入其他</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[13].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">15</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[14].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJSR041</td>
            <td class="center">减计收入</td>
            <td class="left">	金融机构取得的涉农贷款利息收入在计算应纳税所得额时减计收入</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[14].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">16</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[15].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJSR042</td>
            <td class="center">减计收入</td>
            <td class="left">	保险机构取得的涉农保费收入在计算应纳税所得额时减计收入</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[15].amount"></qzf-str-td>
          </tr>
          <tr>
            <td class="center">17</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[16].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJSR043</td>
            <td class="center">减计收入</td>
            <td class="left">	小额贷款公司取得的农户小额贷款利息收入在计算应纳税所得额时减计收入	</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[16].amount"></qzf-str-td>
          </tr> -->
        <!-- 2022年放开 -->
          <!-- <tr>
            <td class="center">18</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[17].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJKC011</td>
            <td class="center">加计扣除</td>
            <td class="left">企业开发新技术、新产品、新工艺发生的研究开发费用加计扣除（制造业按100%加计扣除）	</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[17].amount"></qzf-str-td>
          </tr>

          <tr>
            <td class="center">19</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[18].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJKC012</td>
            <td class="center">加计扣除</td>
            <td class="left">企业开发新技术、新产品、新工艺发生的研究开发费用加计扣除（非制造业按75%加计扣除）	</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[18].amount"></qzf-str-td>
          </tr>

          <tr>
            <td class="center">20</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[19].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJKC021</td>
            <td class="center">加计扣除</td>
            <td class="left">企业为获得创新性、创意性、突破性的产品进行创意设计活动发生的相关费用加计扣除（制造业按100%加计扣除）	</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[19].amount"></qzf-str-td>
          </tr>

          <tr>
            <td class="center">21</td>
            <td class="center">
              <el-checkbox v-model="detailInfo[20].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JJKC022</td>
            <td class="center">加计扣除</td>
            <td class="left">企业为获得创新性、创意性、突破性的产品进行创意设计活动发生的相关费用加计扣除（非制造业按75%加计扣除）	</td>
            <qzf-str-td :disabled="false" v-model:amount="detailInfo[20].amount"></qzf-str-td>
          </tr> -->

        </table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="saveExempt()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 免税、减计收入、加计扣除类型选项表弹框 end -->

    <!-- 所得减免类型选项表弹框 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="所得减免类型选项表" v-model="dialogReduce"  style="width: 70%">
      <table class="content2" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:5%;color: #fff !important">序号</td>
          <td class="center" style="width:5%;color: #fff !important">选择</td>
          <td class="center" style="width:8%;color: #fff !important">代码</td>
          <td class="center" style="width:26%;color: #fff !important">减免项目类别</td>
          <td class="center" style="width:38%;color: #fff !important">优惠事项</td>
          <td class="center" style="width:15%;color: #fff !important">本年累计金额</td>
        </tr>
      </table>
      <div class="fixed_box_table">
        <table class="content2" cellspacing="0">
          <tr>
            <td class="center" style="width:5%;">1</td>
            <td class="center" style="width:5%;">
              <el-checkbox v-model="reduceInfo[0].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left" style="width:8%;">SD011</td>
            <td class="left" style="width:26%;" rowspan="2">农、林、牧、渔业项目</td>
            <td class="left" style="width:38%;">农林牧渔免税项目</td>
            <qzf-str-td style="width:15%;" :disabled="false" v-model:amount="reduceInfo[0].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">2</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[1].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD012</td>
            <td class="left">农林牧渔减半项目</td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[1].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">3</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[2].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD021</td>
            <td class="left" rowspan="2">国家重点扶持的公共基础设施项目</td>
            <td class="left">从事国家重点扶持的公共基础设施项目（除农村饮水工程）投资经营的所得定期减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[2].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">4</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[3].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD022</td>
            <td class="left">从事农村饮水工程新建项目投资经营的所得定期减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[3].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">5</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[4].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD030</td>
            <td class="left">从事符合条件的环境保护、节能节水项目的所得定期减免企业所得税	</td>
            <td class="left"></td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[4].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">6</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[5].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD041</td>
            <td class="left" rowspan="2">符合条件的技术转让项目</td>
            <td class="left">符合条件的一般技术转让项目所得减免征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[5].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">7</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[6].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD042</td>
            <td class="left">符合条件的中关村国家自主创新示范区特定区域技术转让项目所得减免征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[6].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">8</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[7].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD050</td>
            <td class="left">实施清洁发展机制项目的所得定期减免企业所得税			</td>
            <td class="left"></td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[7].amount" ></qzf-str-td>
          </tr>
          <!-- SD060 -->
          <tr>
            <td class="center">9</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[8].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD060</td>
            <td class="left">符合条件的节能服务公司实施合同能源管理项目的所得定期减免企业所得税</td>
            <td class="left"></td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[8].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">10</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[9].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD071</td>
            <td class="left">线宽小于130纳米的集成电路生产项目的所得减免企业所得税		</td>
            <td class="left"></td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[9].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">11</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[10].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD072</td>
            <td class="left">线宽小于65纳米或投资额超过150亿元的集成电路生产项目的所得减免企业所得税		</td>
            <td class="left"></td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[10].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">12</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[11].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD073</td>
            <td class="left">线宽小于28纳米（含）的集成电路生产项目		</td>
            <td class="left"></td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[11].amount" ></qzf-str-td>
          </tr>
           <!-- 74 -->
           <tr>
            <td class="center">13</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[13].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD074</td>
            <td class="left" rowspan="2">集成电路项目新优惠政策</td>
            <td class="left">国家鼓励的线宽小于65纳米(含)的集成电路生产项目</td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[13].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">14</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[14].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD075</td>
            <td class="left">国家鼓励的线宽小于130纳米(含)的集成电路生产项目</td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[14].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">15</td>
            <td class="center">
              <el-checkbox v-model="reduceInfo[12].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">SD999</td>
            <td class="left">其他		</td>
            <td class="left"></td>
            <qzf-str-td :disabled="false" v-model:amount="reduceInfo[12].amount" ></qzf-str-td>
          </tr>
        </table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="saveReduce()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 所得减免类型选项表弹框 end -->

    <!-- 减免所得税类型选项表 -->
    <el-dialog :close-on-click-modal="false" destroy-on-close title="减免所得税类型选项表" v-model="dialogType" style="width: 70%">
      <table class="content2" cellspacing="0" width="100%" >
        <tr class="top_bg">
          <td class="center" style="width:5%;color: #fff !important">序号</td>
          <td class="center" style="width:5%;color: #fff !important">选择</td>
          <td class="center" style="width:10%;color: #fff !important">代码</td>
          <td class="center" style="width:20%;color: #fff !important">类别</td>
          <td class="center" style="width:40%;color: #fff !important">项目</td>
          <td class="center" style="width:15%;color: #fff !important">本年累计金额</td>
        </tr>
      </table>
      <div class="fixed_box_table">
        <table class="content2" cellspacing="0">
          <tr>
            <td class="center" style="width:5%;">1</td>
            <td class="center" style="width:5%;">
              <el-checkbox v-model="typeInfo[0].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left" style="width:10%;">JMSE00100</td>
            <td class="left" style="width:20%;">小微企业</td>
            <td class="left" style="width:40%;">符合条件的小型微利企业减免企业所得税</td>
            <qzf-str-td style="width:15%;" :disabled="false" v-model:amount="typeInfo[0].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">2</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[1].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00201</td>
            <td class="left" rowspan="2">高新技术企业</td>
            <td class="left">高新技术企业减按15%的税率征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[1].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">3</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[2].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00202</td>
            <td class="left">经济特区和上海浦东新区新设立的高新技术企业在区内取得的所得定期减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[2].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">4</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[3].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00301A</td>
            <td class="left" rowspan="9">原有软件集成电路优惠继续执行至到期</td>
            <td class="left">线宽小于0.8微米（含）的集成电路生产企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[3].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">5</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[4].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00302A</td>
            <td class="left">线宽小于0.25微米的集成电路生产企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[4].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">6</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[5].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00303A</td>
            <td class="left">投资额超过80亿元的集成电路生产企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[5].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">7</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[6].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00304A</td>
            <td class="left">新办集成电路设计企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[6].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">8</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[7].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00305A</td>
            <td class="left">符合条件的软件企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[7].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">9</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[8].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00306A</td>
            <td class="left">符合条件的集成电路封装测试企业定期减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[8].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">10</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[9].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00307A</td>
            <td class="left">符合条件的集成电路关键专用材料生产企业、集成电路专用设备生产企业定期减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[9].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">11</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[10].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00308A</td>
            <td class="left">线宽小于130纳米（含）的集成电路生产企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[10].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">12</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[11].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00309A</td>
            <td class="left">线宽小于65纳米（含）或投资额超过150亿元的集成电路生产企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[11].amount" ></qzf-str-td>
          </tr>


          <tr>
            <td class="center">13</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[12].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00301B</td>
            <td class="left" rowspan="10">软件集成电路企业新政策</td>
            <td class="left">国家鼓励的线宽小于28纳米（含）集成电路生产企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[12].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">14</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[13].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00302B</td>
            <td class="left">国家鼓励的线宽小于65纳米（含）集成电路生产企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[13].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">15</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[14].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00303B</td>
            <td class="left">国家鼓励的线宽小于130纳米（含）集成电路生产企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[14].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">16</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[15].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00304B</td>
            <td class="left">国家鼓励的集成电路设计企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[15].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">17</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[16].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00305B</td>
            <td class="left">国家鼓励的重点集成电路设计企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[16].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">18</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[17].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00306B</td>
            <td class="left">国家鼓励的集成电路装备企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[17].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">19</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[18].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00307B</td>
            <td class="left">国家鼓励的集成电路材料企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[18].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">20</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[19].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00308B</td>
            <td class="left">国家鼓励的集成电路封装、测试企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[19].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">21</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[20].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00309B</td>
            <td class="left">国家鼓励的软件企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[20].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">22</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[21].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00310B</td>
            <td class="left">国家鼓励的重点软件企业减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[21].amount" ></qzf-str-td>
          </tr>

          <tr>
            <td class="center">23</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[22].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00401</td>
            <td class="left" rowspan="2">技术先进型服务企业</td>
            <td class="left">技术先进型服务企业（服务外包类）减按15%的税率征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[22].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">24</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[23].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00402</td>
            <td class="left">技术先进型服务企业（服务贸易类）减按15%的税率征收企业所得税税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[23].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">25</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[24].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00501</td>
            <td class="left" rowspan="4">文化、环保、残疾人等特殊类型企业优惠</td>
            <td class="left">动漫企业自主开发、生产动漫产品定期减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[24].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">26</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[25].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00503</td>
            <td class="left">符合条件的生产和装配伤残人员专门用品企业免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[25].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">27</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[26].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00504</td>
            <td class="left">从事污染防治的第三方企业减按15%的税率征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[26].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">28</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[27].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00502</td>
            <td class="left">经营性文化事业单位转制为企业的免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[27].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">29</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[28].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00601</td>
            <td class="left" rowspan="6">区域性优惠政策</td>
            <td class="left">设在西部地区的鼓励类产业企业减按15%的税率征收企业所得税（主营业务收入占比__%)</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[28].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">30</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[29].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00602</td>
            <td class="left">新疆困难地区新办企业定期减免企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[29].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">31</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[30].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00603</td>
            <td class="left">新疆喀什、霍尔果斯特殊经济开发区新办企业定期免征企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[30].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">32</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[31].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00604</td>
            <td class="left">广东横琴、福建平潭、深圳前海等地区的鼓励类产业企业减按15%税率征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[31].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">33</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[32].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00605</td>
            <td class="left">上海自贸试验区临港新片区的重点产业企业减按15%的税率征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[32].amount" ></qzf-str-td>
          </tr>
          <tr>
            <td class="center">34</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[33].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE00606</td>
            <td class="left">海南自由贸易港鼓励类企业减按15%税率征收企业所得税</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[33].amount" ></qzf-str-td>
          </tr>
         
          <tr>
            <td class="center">35</td>
            <td class="center">
              <el-checkbox v-model="typeInfo[34].status" :true-label=1 :false-label=0></el-checkbox>
            </td>
            <td class="left">JMSE99999</td>
            <td class="left">其他</td>
            <td class="left">其他</td>
            <qzf-str-td :disabled="false" v-model:amount="typeInfo[34].amount" ></qzf-str-td>
          </tr>
        </table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" size="small" @click="saveType()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 减免所得税类型选项表 end -->
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'mainTable',
  props: {
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
    qysdssType:{
      default:0,
      type:Number
    },
    source:{
      default:'',
      type:String
    },
  },
  data() {
    return {
      typeList: [{
        value: '新政策',
        label: '新政策'
      }, {
        value: '原政策',
        label: '原政策'
      }], //政策
      detailInfo:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      reduceInfo:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      typeInfo:[{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      assetsArr:[{},{},{}],
      expStatus:false,
      info: {},
      qysdsType:0,
      comName:'',
      durationOfTaxTime:'',
      taxNo:'',
      checked: '0',
      checked2:'0',
      dialogExempt:false,
      dialogReduce:false,
      dialogType:false,
      contentStyleObj:{}, //高度变化

    }
  },
  created(){
    this.contentStyleObj = this.$getHeight(337)
    this.getList()
    if(this.qysdssType){
      this.qysdsType = this.qysdssType
    }else{
      this.qysdsType = this.$store.getters['user/comInfo'].qysds
    }
  },
  methods:{
    changeList(){
        // 季度平均值
     if(this.durationOfTaxTime.slice(16,18) == '03'){
        this.info.jdpjzRs = Math.round((this.info.qccyrs1 *1 + this.info.qmcyrs1*1)/2)
        this.info.jdpjzZC = ((this.info.qczcze1*1 + this.info.qmzcze1*1)/2).toFixed2()*100/100
      }
      if(this.durationOfTaxTime.slice(16,18) == '06'){
        this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2)/2)
        this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2)/2).toFixed2()*100/100
        if(this.info.qccyrs1*1 == 0 && this.info.qmcyrs1*1 == 0){
          this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2))
          this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2)).toFixed2()*100/100
        }
      }
      if(this.durationOfTaxTime.slice(16,18) == '09'){
        this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2 + (this.info.qccyrs3*1 + this.info.qmcyrs3*1)/2)/3)
        this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2 + (this.info.qczcze3*1 + this.info.qmzcze3*1)/2)/3).toFixed2()*100/100

        if(this.info.qccyrs1*1 == 0 && this.info.qmcyrs1*1 == 0){
          this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2)/2)
          this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2)/2).toFixed2()*100/100
        }
        if(this.info.qccyrs1*1 == 0 && this.info.qmcyrs1*1 == 0 && this.info.qccyrs2*1 == 0 && this.info.qmcyrs2*1 == 0){
          this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2 + (this.info.qccyrs3*1 + this.info.qmcyrs3*1))/2)
          this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2 + (this.info.qczcze3*1 + this.info.qmzcze3*1)/2)).toFixed2()*100/100
        }
      }
      if(this.durationOfTaxTime.slice(16,18) == '12'){
        this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2 + (this.info.qccyrs3*1 + this.info.qmcyrs3*1)/2 + (this.info.qccyrs4*1 + this.info.qmcyrs4*1)/2)/4)
        this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2 + (this.info.qczcze3*1 + this.info.qmzcze3*1)/2 + (this.info.qczcze4*1 + this.info.qmzcze4*1)/2)/4).toFixed2()*100/100

        if(this.info.qccyrs1*1 == 0 && this.info.qmcyrs1*1 == 0){
          this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2 + (this.info.qccyrs3*1 + this.info.qmcyrs3*1)/2 + (this.info.qccyrs4*1 + this.info.qmcyrs4*1)/2)/3)
        }
        if(this.info.qccyrs1*1 == 0 && this.info.qmcyrs1*1 == 0 && this.info.qccyrs2*1 == 0 && this.info.qmcyrs2*1 == 0){
          this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2 + (this.info.qccyrs3*1 + this.info.qmcyrs3*1)/2 + (this.info.qccyrs4*1 + this.info.qmcyrs4*1)/2)/2)
        }
        if(this.info.qccyrs1*1 == 0 && this.info.qmcyrs1*1 == 0 && this.info.qccyrs2*1 == 0 && this.info.qmcyrs2*1 == 0 && this.info.qccyrs3*1 == 0 && this.info.qmcyrs3*1 == 0){
          this.info.jdpjzRs = Math.round(((this.info.qccyrs1*1 + this.info.qmcyrs1*1)/2 + (this.info.qccyrs2*1 + this.info.qmcyrs2*1)/2 + (this.info.qccyrs3*1 + this.info.qmcyrs3*1)/2 + (this.info.qccyrs4*1 + this.info.qmcyrs4*1)/2))
        }

        if(this.info.qczcze1*1 == 0 && this.info.qmzcze1*1 == 0){
          this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2 + (this.info.qczcze3*1 + this.info.qmzcze3*1)/2 + (this.info.qczcze4*1 + this.info.qmzcze4*1)/2)/3).toFixed2()*100/100
        }
        if(this.info.qczcze1*1 == 0 && this.info.qmzcze1*1 == 0 && this.info.qczcze2*1 == 0 && this.info.qmzcze2*1 == 0){
          this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2 + (this.info.qczcze3*1 + this.info.qmzcze3*1)/2 + (this.info.qczcze4*1 + this.info.qmzcze4*1)/2)/2).toFixed2()*100/100
        }
        if(this.info.qczcze1*1 == 0 && this.info.qmzcze1*1 == 0 && this.info.qczcze2*1 == 0 && this.info.qmzcze2*1 == 0 && this.info.qczcze3*1 == 0 && this.info.qmzcze3*1 == 0){
          this.info.jdpjzZC = (((this.info.qczcze1*1 + this.info.qmzcze1*1)/2 + (this.info.qczcze2*1 + this.info.qmzcze2*1)/2 + (this.info.qczcze3*1 + this.info.qmzcze3*1)/2 + (this.info.qczcze4*1 + this.info.qmzcze4*1)/2)).toFixed2()*100/100
        }
      }
      if(!this.expStatus){
        return
      }
      // this.info[0].y01001 = e
      // if(e != ''){
      //   this.info[0].y01001Status = true
      // }else{
      //   this.info[0].y01001Status = false
      // }

      let a = 2522.115
      //console.log(Math.round(a * 100) / 100)
      this.info.sjlre = (this.info.lrze *1 + 
                            this.info.tdywjsdynssde *1 -
                            this.info.bzssr *1 -
                            this.info.zcjszj*1 -
                            this.info.mssr*1 -
                            this.info.sdsjm*1 - 
                            this.info.mbyqndks*1).toFixed(2)
      this.info.ynssde = this.info.sjlre < 0 ? '':(this.info.sjlre*1 * this.info.sl*1 ).toFixed(2)
      if(this.info.xxwlqyjd == '1'){
        this.info.jmsdse = this.info.sjlre <= 1000000 && this.info.sjlre > 0 ? ( this.info.sjlre * 0.25 * 0.9 ).toFixed(2) :(this.info.sjlre >= 1000000 && this.info.sjlre <= 3000000 ? ( (this.info.sjlre - 1000000) * 0.15 + 225000 ).toFixed(2) : "")
      } 
      this.info.bqybt = (this.info.ynssde*1 -
                            this.info.jmsdse*1 -
                            this.info.bnsjyjn*1 -
                            this.info.tdyeyj*1 ).toFixed(2)*1 < 0 ? '' : (this.info.ynssde -
                            this.info.jmsdse*1 -
                            this.info.bnsjyjn*1 -
                            this.info.tdyeyj*1 ).toFixed(2)
      this.info.zyjsr = (this.info.bqybt * 0.6).toFixed(2)
      this.info.dfjsr = (this.info.bqybt * 0.4).toFixed(2)
      if(this.info.mzzzqStatus == 2 && this.info.mzzzqFd){
        this.info.mzzzqBq = (this.info.dfjsr*((this.info.mzzzqFd*1)/100)).toFixed(2)
      }else if(this.info.mzzzqStatus == 0 || this.info.mzzzqStatus == 1){
        this.info.mzzzqBq = ''
        this.info.mzzzqFd = ''
      }
      this.info.mzzzqBn = this.info.mzzzqBq
      this.info.dfjsrsjynse = (this.info.dfjsr*1 - this.info.mzzzqBq*1).toFixed(2)
      this.info.sjybtse = (this.info.zyjsr*1 +
                              this.info.dfjsrsjynse*1).toFixed(2)
    },
    // 主表
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_tax_quarter',
          period:this.startAccountPeriod,
          itemName:'main',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info && res.data.data.info.length != 0){
            this.info = res.data.data.info
            this.changeList()
          }
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          this.durationOfTaxTime = res.data.data.time
          this.changeList()
        })
      }else{
        gsInfo({tableName: 'gs_tax_quarter',itemName:'main'}).then(res=>{
          if(res.data.data.info && res.data.data.info.length != 0){
            this.info = res.data.data.info
            // this.changeList()
          }
          this.comName = res.data.data.name
          this.taxNo = res.data.data.taxNo
          this.durationOfTaxTime = res.data.data.time
          this.changeList()
        })
      }

    },
    // fb1
    openExempt(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_tax_quarter',
          period:this.startAccountPeriod,
          itemName:'fb1',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.detailInfo = res.data.data.info
            this.dialogExempt = true
          }
        })
      }else{
        gsInfo({tableName: 'gs_tax_quarter',itemName:'fb1'}).then(res=>{
          if(res.data.data.info){
            this.detailInfo = res.data.data.info
            this.dialogExempt = true
          }
        })
      }
    },
    // fb2
    openReduce(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_tax_quarter',
          period:this.startAccountPeriod,
          itemName:'fb2',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info && res.data.data.info.length == 15){
            this.reduceInfo = res.data.data.info
            this.dialogReduce = true
          }else{
            this.$qzfMessage('所得减免表更新，请重新取数',1)
          }
        })
      }else{
        gsInfo({tableName: 'gs_tax_quarter',itemName:'fb2'}).then(res=>{
          if(res.data.data.info && res.data.data.info.length == 15){
            this.reduceInfo = res.data.data.info
            this.dialogReduce = true
          }else{
            this.$qzfMessage('所得减免表更新，请重新取数',1)
          }
        })
      }
    },
    // fb3
    openType(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_tax_quarter',
          period:this.startAccountPeriod,
          itemName:'fb3',
          comId:this.comId
        }
        if(this.source){
          param.method = this.$store.getters["commons/params"].initMethod
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.typeInfo = res.data.data.info
            this.dialogType = true
          }
        })
      }else{
        gsInfo({tableName: 'gs_tax_quarter',itemName:'fb3'}).then(res=>{
          if(res.data.data.info){
            this.typeInfo = res.data.data.info
            this.dialogType = true
          }
        })
      }
    },
    saveMain(){
      if(this.startAccountPeriod){
        let param = {
          tableName:'gs_tax_quarter',
          itemName:'main',
          items:[this.info],
          period:this.startAccountPeriod,
          comId:this.comId,
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_tax_quarter',
          itemName:'main',
          items:[this.info]
        }
        gsEdit(param).then(res=>{
          if(res.data.msg == "success"){
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
    // fb1
    saveExempt(){
      let param = {
        tableName:'gs_tax_quarter',
        itemName:'fb1',
        items:this.detailInfo
      }
      gsEdit(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.dialogExempt = false
          this.computedMssr()
          // this.getList()
        }
      })
    },
    computedMssr(){
      let sum = 0
      this.detailInfo.map(v=>{
        if(v.status && v.amount){
          sum += Number(v.amount)
        }
      })
      this.info.mssr = sum.toFixed(2);
      this.changeList()
    },
    // fb2
    saveReduce(){
      let param = {
        tableName:'gs_tax_quarter',
        itemName:'fb2',
        items:this.reduceInfo
      }
      gsEdit(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.dialogReduce = false
          this.computedSdsjm()
          // this.getList()
        }
      })
    },
    computedSdsjm(){
      let sum = 0
      this.reduceInfo.map(v=>{
        if(v.status && v.amount){
          sum += Number(v.amount)
        }
      })
      this.info.sdsjm = sum.toFixed(2);
      this.changeList()
    },
    // fb3
    saveType(){
      let param = {
        tableName:'gs_tax_quarter',
        itemName:'fb3',
        items:this.typeInfo
      }
      gsEdit(param).then(res=>{
        if(res.data.msg == "success"){
          this.$qzfMessage("保存成功")
          this.dialogType = false
          // this.getList()
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
  font-weight: 600;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 4em;
}
.left_big_indent{
  text-indent: 8em;
}
.border_none .el-select{
  width: 100% !important;
}
.border_none .el-input__inner{
  width: 100% !important;
  background-color: none !important;
}
.input_width input{
  width:60px !important;
  margin: 0 5px;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
}
.blue_box{
  background: #fff ;
}
.el-radio+.el-radio {
    margin-left: 7px;
}
.el-radio__label{
  font-size: 13px !important;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.app-container{
  position: relative;
  .right_btns{
    position: absolute;
    top: 20px;
    right: 40px;
    z-index: 99;
  }
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 25px;
    padding:0 6px;
    color: #333;
    font-size: 13px;
    p{
      display: inline-block;
      color: #409EFF;
      cursor: pointer;
    }
  }
  input{
    width: 100%;
    // line-height: 28px;
    // padding: 0 6px;
    font-size: 13px;
    text-align: right;
    padding-right: 12px;
  }
}
.content2{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 13px;
    p{
      display: inline-block;
      color: #409EFF;
      cursor: pointer;
    }
  }
  input{
    width: 100%;
    line-height: 26px;
    // padding: 0 6px;
    font-size: 13px;
    text-align: right;
    padding-right:20px;
  }
}


.big_box4{
  width: 100%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  box-sizing: border-box;
  // overflow: auto;
}
</style>
