<template>
  <div style="position: relative;">
    <div class="big_box4">
      <div class="top_title3">
        <h4>{{title}}</h4>
        <h5>一般纳税人、小规模纳税人适用</h5>
        <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div>
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
         <td class="center" style="width:40%;" >项目</td>
         <td class="center" style="width:20%;" >行次</td>
         <td class="center" style="width:20%;">本期数</td>
         <td class="center" style="width:20%;">本年累计</td>
        </tr>
        <tr v-for="item in list" :key="item.id">
          <td class="bg_color">{{item.name}}</td>
          <td class="center bg_color">{{item.type}}</td>
          <qzf-str-td style="width:10%" v-model:amount="item.periodTotal" @change="changeList()"></qzf-str-td>
          <qzf-str-td style="width:10%" v-model:amount="item.yearTotal" @change="changeList()"></qzf-str-td>
        </tr>
      </table>

    </div>
    <div class="save_box" v-if="$buttonStatus('swbb_bc')">
      <el-button @click="saveValues" type="primary" size="small" v-if="this.startAccountPeriod"><i class="iconfont icon-baocun"></i>保存</el-button>
      <qzf-button @success="saveValues" type="primary" size="small" v-else><i class="iconfont icon-baocun"></i>保存</qzf-button>
    </div>
  </div>
</template>

<script>
import { gsInfo , gsEdit} from '@/api/taxCalculation.js'
export default {
  name:'xfsMain',
  props: {
    title: String,
    startAccountPeriod:{
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      listQuery: {},
      comName:this.$store.getters['user/comInfo'].comName,
      taxNo:this.$store.getters['user/comInfo'].taxNo,
      list:[{}],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    // 公式
    changeList(){

    },
    getList(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_xfs',
          itemName:'main',
          period:this.startAccountPeriod,
          comId:this.comId
        }
        gsInfo(param).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
            this.comName = res.data.data.name
            this.taxNo = res.data.data.taxNo
          }
        })
      }else{
        gsInfo({tableName: 'gs_xfs',itemName:'main'}).then(res=>{
          if(res.data.data.info){
            this.list = res.data.data.info
          }
        })
      }
    },
    saveValues(){
      if(this.startAccountPeriod){
        let param = {
          tableName: 'gs_xfs',
          itemName:'main',
          period:this.startAccountPeriod,
          comId:this.comId,
          items: this.list
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }else{
        let param = {
          tableName:'gs_xfs',
          itemName:'main',
          items: this.list
        }
        gsEdit(param).then(res => {
          if(res.data.msg == "success") {
            this.$qzfMessage("保存成功")
            this.getList()
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}
.width_mini{
  width: 80px;
}
.width_small{
  width: 220px;
}
.width_big{
  width: 280px;
}
.width_date{
  width: 160px;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: right;
}
.center{
  text-align: center;
  color: #333 !important;
}
.weight{
  font-size: 13px !important;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.left_k{
  text-indent: 41px;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  position: relative;
  .add {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    left: -28px;
    top: 9px;
    font-size: 16px;
    color: #f15a24;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
  span{
    line-height: 26px;
    font-size: 13px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 26px;
    padding:0 6px;
    color: #666;
    font-size: 13px;
    text-align: center;
  }
  input{
    width: 100%;
    line-height: 26px;
    padding: 0 6px;
    font-size: 13px;
    text-align: right;
    padding-right: 12px;
  }
}
.close_item{
  position: relative;
  .close {
    width: 1.4em;
    height: 1.4em;
    vertical-align: -0.15em;
    fill: currentColor;
    position: absolute;
    right: -32px;
    top: 0;
    font-size: 20px;
    color: #ff1818;
    cursor: pointer;
    .svg-icon {
      width: 100%;
      height: 100%;
      vertical-align: -0.15em;
      fill: currentColor;
      overflow: hidden;
    }
  }
}
.big_box4{
  width: 100%;
  background: #fff;
  margin: 0 auto;
  box-sizing: border-box;
}
.addi{
  cursor: pointer;
  position: absolute;
  top: 5px;
  left: -26px;
  font-size: 22px;
  color: var(--themeColor,#17a2b8);
}
.content tr td:first-child {
  width: 35%;
}
</style>